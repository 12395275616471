<!--设备列表-->
<template>
	<div class="page">
		<Tables :showSeq="false" ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true"
			:showSearch="true" :showAdd="$hasAccess('warehouse.warehouse/index')" addName="新增物料" @getList="getList" @clearSearch="clearSearch" @addItemInTable="addOne">
			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.type" placeholder="按设备类型查询" clearable size="small" class="from-item-width">
					<el-option label="设备" :value="10">
					</el-option>
					<el-option label="物料" :value="20">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.wl_name" size="small" clearable placeholder="按名称查询" class="from-item-width"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.ck_location" placeholder="按仓库查询" clearable size="small" class="from-item-width">
					<el-option label="苏州" :value="1">
					</el-option>
					<el-option label="无锡" :value="2">
					</el-option>
					<el-option label="张家港" :value="3">
					</el-option>
				</el-select>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="group_id" title="类型" align="center" >
				<template v-slot="{ row }">
					<span>{{type[row.type]||'未知类型'}}</span>
				</template>

			</vxe-table-column>
			<vxe-table-column slot="table-item" field="wl_name" title="名称" align="center" min-width="200px" />
			<vxe-table-column slot="table-item" field="num" title="物料数量" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="type" title="仓库" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span>{{ck[row.ck_location]||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" width="270">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="edit(row)" v-if="$hasAccess('group.wuye/edit')">编辑</el-button>
					<el-button size="small" plain @click="deleteOne(row)" v-if="$hasAccess('device.device/delete')">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>
		<!-- 新增 -->
		<el-dialog title="新增物料" :visible.sync="addDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="400px">
			<el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="auto" class="add-dialog-form">
				<el-form-item label="物料名称" prop="wl_name">
					<el-input v-model="addDialogFormData.wl_name" placeholder="请输入物料名称" clearable></el-input>
				</el-form-item>
				<el-form-item label="物料数量" prop="num">
					<el-input-number v-model="addDialogFormData.num" controls-position="right" :min="0" :step="1" :precision="0" style="width: 80%;"></el-input-number>
				</el-form-item>
				<el-form-item label="所属仓库" prop="ck_location">
					<el-select class="select" v-model="addDialogFormData.ck_location" placeholder="请选择所属仓库" clearable>
						<el-option :key="1" label="苏州" :value="1"></el-option>
						<el-option :key="2" label="无锡" :value="2"></el-option>
						<el-option :key="2" label="张家港" :value="2"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()" v-if="$hasAccess('warehouse.warehouse/create')">确 认 新 增 </el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消 </el-button>
			</div>
		</el-dialog>
		<!-- 编辑 -->
		<el-dialog title="编辑" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="400px">
			<el-form :model="editDialogFormData" :rules="rules" ref="addForm" label-width="auto" class="add-dialog-form">
				<el-form-item label="物料名称" prop="wl_name">
					<el-input v-model="editDialogFormData.wl_name" placeholder="请输入物料名称" clearable></el-input>
				</el-form-item>
				<el-form-item label="物料数量" prop="num">
					<el-input-number v-model="addDialogFormData.num" controls-position="right" :min="0" :step="1" :precision="0" style="width: 80%;"></el-input-number>
				</el-form-item>
				<el-form-item label="所属仓库" prop="ck_location">
					<el-select class="select" v-model="editDialogFormData.ck_location" placeholder="请选择所属仓库" clearable>
						<el-option :key="1" label="苏州" :value="1"></el-option>
						<el-option :key="2" label="无锡" :value="2"></el-option>
						<el-option :key="2" label="张家港" :value="3"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveedit()" v-if="$hasAccess('group.wuye/edit')">保 存 编 辑 </el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消 </el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import Tables from "@/components/tables";
	export default {
		name: "property-bill-management",
		components: {
			Tables,
		},
		data() {
			return {
				// table信息
				tableName: "设备清单",
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {},
				billList: [],
				selecttime: {},
				editselecttime: {},
				groupList: [],
				groupLoading: false,
				ck: {
					1: "苏州",
					2: "无锡",
					3: "张家港"
				},
				type: {
					1: "设备",
					2: "二路插座",
					3: "物料"
				},
				addDialogShow: false,
				addDialogFormData: {},
				editDialogShow:false,
				editDialogFormData: {},
				rules: {
					wl_name: [{
						required: true,
						message: '请输入物料名称',
						trigger: ['blur', 'change']
					}],
					ck_location: [{
						required: true,
						message: '请选择仓库',
						trigger: ['blur', 'change']
					}],
					num: [{
						required: true,
						message: '请输入数量',
						trigger: ['blur', 'change']
					}],
				}
			};
		},
		mounted() {
			this.init();
		},
		activated() {
			this.init();
			this.$refs.xTable.refreshTable();
		},
		methods: {
			// 初始化请求
			init() {

			},
			// 获取列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				};
				this.isLoading = true;
				const searchData = this.searchFormData;
				const res = await this.$api.Ware.GetWareList(params, searchData);
				this.tableData = res.data;
				this.totalPage = res.total;
				this.isLoading = false;
			},
			// 提交记录
			async addPropertyBill() {
				alert(1);return ;
				const params = {
					token: this.$store.state.user.token,
					form: JSON.stringify(this.addDialogFormData),
				}
				await this.$api.Bill.AddPropertyBill(params);
				this.$notify({
					title: "成功",
					message: "添加成功",
					type: "success",
				});
				this.closeDialog();
				this.getList();
			},
			// 小区
			async remoteGroup (query) {
				if (query !== '') {
					this.groupLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const res = await this.$api.Device.GetGroupList(params, {
						group_name: query
					})
					this.groupList = res.data
					this.groupLoading = false
				} else {
					this.groupList = []
				}
			},
			async onPayment(row) {
				const params = {
					token: this.$store.state.user.token,
					id: row.id
				}
				await this.$api.Bill.PropertyPay(params);
				this.$notify({
					title: "成功",
					message: "结算成功",
					type: "success",
				});
				this.getList();
			},
			// 重置查询
			clearSearch() {
				this.searchFormData = {};
			},
			// 新增设备
			addOne() {
				this.addDialogShow = true;
			},
			saveAdd() {
				if (!this.addDialogFormData.num) {
					this.$refs.addForm.validateField('num');
					return;
				}
				if (!this.addDialogFormData.wl_name) {
					this.$refs.addForm.validateField('wl_name');
					return;
				}
				if (!this.addDialogFormData.ck_location) {
					this.$refs.addForm.validateField('ck_location');
					return;
				}
				this.addPropertyBill();
			},
			async edit (row) {
				this.editDialogFormData = {};
				this.editDialogShow = true;
				this.editselecttime={};
				const params = {
					token: this.$store.state.user.token,
					id: row.id
				}
				const res = await this.$api.Bill.showbyid(params);
				if (res) {
					const {
						id,
						group_id,
						type,
						record_money,
						payment_time,
						remark
					} = res;
					this.editDialogFormData = {
						id,
						group_id,
						type,
						record_money,
						payment_time,
						remark
					};
					this.editDialogFormOriData = {
						id,
						group_id,
						type,
						record_money,
						payment_time,
						remark
					};
				}
				this.editDialogShow = true;
			},
			saveedit() {
				if (!this.editDialogFormData.group_id) {
					this.$refs.editForm.validateField('group_id');
					return;
				}
				if (!this.editDialogFormData.type) {
					this.$refs.editForm.validateField('type');
					return;
				}
				if (!this.editDialogFormData.record_money) {
					this.$refs.editForm.validateField('record_money');
					return;
				}
				this.editGroupBill();
			},
			// 提交编辑
			async editGroupBill() {
				if (this.editDialogFormData.payment_time.length==undefined) {
					this.editselecttime= parseInt(this.editDialogFormData.payment_time.getTime() / 1000);
					this.editDialogFormData.edit_time=this.editselecttime;
				}else {
					if (this.editDialogFormData.payment_time){
						this.editDialogFormData.edit_time=this.editDialogFormData.payment_time;
					}else {
						this.editDialogFormData.edit_time="";
					}
				}
				const form = {
					id: this.editDialogFormData.id,
					group_id: this.editDialogFormData.group_id,
					type: this.editDialogFormData.type,
					record_money: this.editDialogFormData.record_money,
					payment_time: this.editDialogFormData.edit_time,
					remark: this.editDialogFormData.remark
				}
				const params = {
					token: this.$store.state.user.token,
					form: JSON.stringify(form),
				}
				await this.$api.Bill.editWuye(params);
				this.$notify({
					title: "成功",
					message: "编辑成功",
					type: "success",
				});
				this.closeDialog();
				this.getList();
			},
			closeDialog() {
				this.addDialogShow = false;
				this.editDialogShow = false;
			}
		},
	};
</script>
<style lang="less" scoped>
	.from-item-width {
		width: 180px;
	}

	.sd_spare {
		color: #008e4d;
	}

	.sd_spare2 {
		color: #0055ff;
	}

	.sd_error {
		color: #f56c6c;
	}

	.sd_total {
		color: #b28e2f;
	}

	.add-dialog-form {
		.select {
			width: 100%;
		}
	}
</style>
<style>
	.el-popover {
		text-align: right;
	}
</style>
